import React from 'react';
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from 'react-redux';
import './BannerPopUp.scss'

import { setBannerPopUp } from '../../store/bannerPopUp/bannerPopUp.slice';
import closeLogin from '../../assets/images/closeLogin.svg'
import ReactGA from "react-ga4";
import { useHistory } from "react-router";
import { CONFIG } from "../../config/api.config";

const modalRoot = document.querySelector("#modal-root");

const BannerPopUp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const banners = useSelector(state => state.promotions)

  return createPortal(
    ( banners?.afterLoginPopup?.length > 0 && <div className="BannerPopUp">
      <div className="BannerPopUp_wrapper" onClick={(e) => {
          if (e.currentTarget === e.target) {
            dispatch(setBannerPopUp(false))
          }
        }}>
        <div className="BannerPopUp">
          <div className="BannerPopUp-content">
            <img className='BannerPopUp-content__image' style={{background: banners?.afterLoginPopup[0]?.backgroundColour}} src={banners?.afterLoginPopup[0]?.image} alt=''
                 onClick={(e) => {
                   if (banners?.afterLoginPopup[0]?.redirect && banners?.afterLoginPopup[0]?.redirect !== "") {
                     if (banners?.afterLoginPopup[0]?.redirect?.startsWith("http")) {
                       ReactGA.event({
                         label: "Banner-Popup",
                         category: "User-Click",
                         action: banners?.afterLoginPopup[0]?.name + "_Banner_Popup"
                       });
                       // window.open(`${banners?.afterLoginPopup[0]?.redirect}`)
                       window.location.href = banners?.afterLoginPopup[0]?.redirect
                     } else {
                         if (e.currentTarget === e.target) {
                           dispatch(setBannerPopUp(false))
                         }
                        history.push(banners?.afterLoginPopup[0]?.redirect)
                     }
                   }
                 }} />
            <img className='closePopUpButtonn' src={closeLogin} alt='' onClick={() => {
              ReactGA.event({
                label: "Banner-Popup",
                category: "Close-Banner-Popup",
                action: banners?.afterLoginPopup[0]?.name + "_Banner_Popup"});
              dispatch(setBannerPopUp(false))

            }}/>
          </div>
        </div>
      </div>
    </div>),
    modalRoot
  )
}

export default BannerPopUp
