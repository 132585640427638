// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { IonSpinner } from '@ionic/react'

import {
  default as PhoneInput,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'
import { useHistory } from 'react-router'
import { IonInput, IonItem, IonList } from '@ionic/react'
import { connectLoginPopUp } from '../loginPopUp.connect'
import { CONFIG as social, CONFIG } from '../../../config/api.config'
import SignUpStep1 from './images/step1.svg'
import SignUpStep2 from './images/step2.svg'
import SignUpStep4 from './images/step4.svg'
import SignUpStep1of3 from './images/step1of3.svg'
import SignUpStep2of3 from './images/step2of3.svg'
import GoogleIcon from '../LogInStep/google-icon.svg'
import WhatsappIcon from '../LogInStep/whatsup-icon1.svg'
import allSet from './images/allSet.png'
import nonStop from '../../../assets/images/nonStop.png'
import support from '../../../assets/images/support.png'
import instantDeposit from '../../../assets/images/instantDeposit.png'
import rulesAccepted from './images/checkBox.svg'
import eye from '../../../assets/images/eye.svg'
import eyeOff from '../../../assets/images/eyeOff.svg'
import closeLogin from '../../../assets/images/closeCross2.svg'
import arrow from '../../../assets/images/arrow.svg'
import promoDefault from '../../../assets/images/promoDefault.png'
import { setTab } from '../../../store/LoginNew/loginNew.slice'
import httpAuth from "../../../services/httpAuth";
import { getSignUpSuccess } from "../../../store/signUp/signUp.slice";
import httpNotAuth from "../../../services/httpNotAuth";
import Cookies from "js-cookie";

const SignUpTab = ({ actions, recaptchaResponse, onDidDismiss, changeTab, width, promoListStore, signUp }) => {
  const [mobile, setMobile] = useState(false)
  const [countryCode, setCountryCode] = useState(false)

  const [isValid, setIsValid] = useState(false)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(5)
  const [isSending, setIsSending] = useState(false)
  const [memberCode, setMemberCode] = useState()
  const [token, setToken] = useState()
  const refRecaptcha = useRef(null)
  const history = useHistory()

  const [mobileNumCounter, setMobileNumCounter] = useState(0)

  const [hideFirstPass, setHideFirstPass] = useState(false)
  const [hideSecondPass, setHideSecondPass] = useState(false)
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState()
  const [googleLoginWrapper, setGoogleLoginWrapper] = useState()
  const [loading, setLoading] = useState(false)
  // console.log('step123', step)


  // useEffect(() => {
  //   if (step === 1 || step === 5){
  //     if(width > 1024) {
  //       setStep(1)
  //       console.log('stepDESK', step)
  //     } else {
  //       setStep(5)
  //       console.log('stepMOB', step)
  //     }
  //     console.log('stepEND', step)
  // }
  // }, [width])

  // useEffect(() => {
  //     if(width > 1024) {
  //       setStep(1)
  //       console.log('stepDESK', step)
  //     } else {
  //       setStep(5)
  //       console.log('stepMOB', step)
  //     }
  //     console.log('stepEND', step)
  // }, [])

  const mobileValidation = async (e) => {
    setIsSending(true)

    if (mobile.length !== 10) {
      setMessage('This field is required!')
      setIsSending(false)
      return
    } else if (isValid) {
      // actions.requestRecaptcha()

      setMessage('')
      const recaptchaResponseExecute =
        await recaptchaResponse.current.executeAsync()
      console.log('recaptchaResponse', recaptchaResponseExecute)
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberSignUp(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            setIsSending(false)
            setStep(2)
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e}`)
        })

      console.log('SENDING MOB')
    }
  }

  const getBalance = (memberCode) => {
    actions
      .requestBalance(memberCode)
      .then((res) => {
        if(res.data.result.availBalance < 100) {
          // history.push("/deposit")
        }
      })
      .catch((e) => console.log('balance error = ', e))
  }

  const login = async () => {
    try {
      if (!mobile || !password) return
      setIsSending(true)
      const recaptchaResponseExecute = await recaptchaResponse.current.executeAsync()

      let newUserName = ''
      newUserName = `91_${mobile}`
      await actions
        .requestAuth({
          username: newUserName,
          password,
          recaptchaResponse: recaptchaResponseExecute,
          answer: '',
          token: '',
        })
        .then((res) => {
          console.log("res",   res)
          getBalance(res.data.result.memberCode)
          onDidDismiss()
          setIsSending(false)
        })
    } catch (e) {
      setIsSending(false)
      //TODO: handle this
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const initialMinute = 0,
    initialSeconds = 59
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    setGoogleLoginWrapper(createGoogleWrapper());
    if (step === 2) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  },[])

  const validateMobile = (value) => {
    setMobileNumCounter(value.length)
    setIsSending(false)

    try {
      // radar start
      if (!mobile.length && value.length === 1 && !otp && !message) {
        const startSignUp = window.srtmCommands.push({event: "track.user.registration",payload: {action: "start"}})
        console.log("!! startSignUp", startSignUp)
      }
      // radar end
    } catch (e) {

    }


    if (!value) {
      setMessage('This field is required!')
      return
    } else if (value.length !== 10) {
      setMessage('Phone number must be 10 digits')
      setIsValid(false)
    } else if (isPossiblePhoneNumber(`+91${value}`)) {
      setIsValid(true)
      setMobile(value)
      const phoneNumber = parsePhoneNumber(`+91${value}`)
      if (phoneNumber) {
        const code = phoneNumber.countryCallingCode
        setCountryCode(code)
        setMessage('')
      }

      setMessage('')
    }
    console.log(isPossiblePhoneNumber(`+91${value}`))
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      // submit()
    }
  }

  const [otpMessage, setOtpMessage] = useState('')
  const [isOtpValid, setIsOtpValid] = useState(false)
  const [password, setPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isConfirmValid, setConfirmIsValid] = useState(false)

  const [passwordMessage, setPasswordMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const [hasErrored, setHasErrored] = useState('')

  const [isRulesAccepted, setIsRulesAccepted] = useState(false)
  const [isRulesAccepted2, setIsRulesAccepted2] = useState(false)


  const ref_input = useRef()
  const [otp, setOtp] = useState()

  const [isPromoValid, setIsPromoValid] = useState(false)
  const [promoMessage, setPromoMessage] = useState('')
  const [isPromoSending, setIsPromoSending] = useState(false)
  const [isRulesVisible, setIsRulesVisible] = useState(false)
  const [chosenPromo, setChosenPromo] = useState({})
  const [showDetails, setShowDetails] = useState(false)
  const [promoList, setPromoList] = useState(null)
  const [detailsPromo, setDetailsPromo] = useState(null)
  const [promoCode, setPromoCode] = useState(null)
  const [isDisable, setIsDisable] = useState()
  const [isSignUpDone, setIsSignUpDone] = useState(false)
  // console.log(step);
  // console.log("promoList", signUp);
  // console.log("promoListStore", promoListStore);

  useEffect(() => {
    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', e => {
      const input = document.querySelector('input[autocomplete="one-time-code"]');
      if (!input) return;

      navigator.credentials.get({
        otp: { transport:['sms'] }
      }).then(otp => {
        input.value = otp.code;
      }).catch(err => {
        console.log(err);
      });
    });
  }
}, []);

  const isDisabledButton = () => {
    if (
      isSending ||
      otpMessage ||
      confirmMessage ||
      passwordMessage ||
      !isPasswordValid ||
      !isConfirmValid ||
      !isPasswordValid
    ) {
      return true
    } else {
      return false
    }
  }

  const isDisabledButton2 = () => {
    if (
      !isRulesAccepted
    ) {
      return true
    } else {
      return false
    }
  }

  const isDisabledButton3 = () => {
    if (
      !isRulesAccepted
    ) {
      return true
    } else {
      return false
    }
  }

  const validatePassword = (password) => {
    // event.preventDefault();
    setIsSending(false)
    const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
    const regexTesting = regex.test(password)

    if (password.length < 8 || password.length > 40) {
      setIsPasswordValid(false)
      setPasswordMessage('The password must be between 8 and 40 characters')
    } else if (!regexTesting) {
      console.log('regex tested')
      setIsPasswordValid(false)
      setPasswordMessage(
        'This field can contain letters(a-z), numbers(0-9) or special symbols'
      )
    } else {
      setIsPasswordValid(true)
      setPasswordMessage('')
      setConfirmIsValid(true)
      setConfirmMessage(true)
      setPassword(password)
    }
  }

  const validatePasswordConfirm = (confirmPassword) => {
    setIsSending(false)
    // event.preventDefault();

    // const confirmPassword = event.target.value;

    if (confirmPassword.length < 8 || confirmPassword.length > 40) {
      setConfirmIsValid(false)
      setConfirmMessage('The password must be between 8 and 40 characters')
    } else {
      setConfirmIsValid(true)
      setConfirmMessage('')
      setPasswordMessage('')
      setIsPasswordValid(true)
      setConfirmPassword(confirmPassword)
    }
  }

  const validateOtp = (otp) => {
    setIsSending(false)
    // const otp = event.target.value;

    if (otp.length != 4) {
      setIsOtpValid(false)
      setOtpMessage('OTP is incorrect!')
    } else {
      ref_input.current.focus()
      setIsOtpValid(true)
      setOtpMessage('')
      setOtp(otp)
    }
  }

  const resendOTP = async () => {
    const dataOTP = {
      countryCode: `+${countryCode}`,
      mobileNumber: `${mobile}`,
    }

    await actions
      .resendOTP(dataOTP)
      .then((res) => {
        if (res.status === 200) {
          toastr.success('', `${res.data.result}`)
          setSeconds(59)
        }
      })
      .catch((e) => toastr.error('', `${e}`))
  }

  const next = async (e) => {
    setIsSending(true)
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      console.log('OTP Empty')
      setIsSending(false)
    } else if (otp.length != 4) {
      setIsValid(false)
      setHasErrored(true)
      setOtpMessage('OTP you entered is invalid')
      console.log('OTP invalid')
      setIsSending(false)
    } else if (!password || !confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('This field is required!')
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      console.log('pass do not match')
      setIsSending(false)
    } else if (password !== confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('Passwords do not match')
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      console.log('pass do not match')
      setIsSending(false)
    } else if (
      isOtpValid &&
      isValid &&
      isConfirmValid &&
      otp.length === 4 &&
      password === confirmPassword
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      console.log('VALIDATION PASSED')
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        OTP: `${otp}`,
        password: `${password}`,
        confirmPassword: `${confirmPassword}`,
        atag: Cookies.get('atag'),
        clickId: Cookies.get('clickId'),
      }


      await actions
        .verifyOTP(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            setMemberCode(res?.data?.result?.memberCode);
            setToken(res.headers.authorization)
            setIsSignUpDone(true)
            setIsSending(false)
          }
          if (promoList?.length){
            // setPromoList(promoListStore)
            setChosenPromo(promoList[0])
            setStep(3)
          } else {
            setStep(4)
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e}`)
        })

      // setPreVal(true)
    }
  }
  useEffect(() => {
    if (signUp?.length){
      setPromoList(signUp)
        }
  }, [signUp]);

  useEffect(() => {
    try {
      httpAuth.get('/promotions').then((response) => {
        if (response.data?.success){
          if(response.data.result && response.data.result?.length > 0){
            setPromoCode(response.data.result[0].promoCode);
            setPromoMessage(response.data.result[0].tagLine);
          }
        }
      })
    } catch (e) {
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }

  }, []);

  // useEffect(() => {
  //   setPromoCode(chosenPromo.promoCode)
  // }, [chosenPromo])

  const changeChosenPromo = (item) => {
    if (chosenPromo.tagLine === item.tagLine) {
      setChosenPromo({})
      setPromoCode('')
      setPromoMessage('')
    } else {
      setChosenPromo(item)
    }
  }

  const checkPromo = async () => {
    const data = { "promoCode": promoCode }

    await actions.postCheckPromo(memberCode, token, data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            setPromoMessage('')
            setIsPromoSending(false)
            res.status === 200 ? setIsPromoValid(true) : setIsPromoValid(false)
            // setTimeout(() => nextStep(), 2000)
            console.log("PROMO POST PASSED");
            setStep(4)
            // actions.setTab(1)
            // onDidDismiss(false)
          }
        })
      .catch((error) => {
        console.log("Error")
        toastr.error('', `${error}`)
        setTimeout(() => {
          // setAnimation("")
          setIsPromoSending(false)
        }, 1000)
        setChosenPromo({})
        setPromoCode('')
        setPromoMessage(error?.response?.data?.error)
      })
  }

  const validatePromo = (event) => {
    setPromoCode(event.target.value)
  }

  const nextPromo = e => {
    e.preventDefault()
    setIsSending(true)

    if (width > 1024 ? isRulesAccepted : true) {

      if (promoList.filter(item => item.promoCode === promoCode).length >= 1) {
        setPromoMessage("")
        // setAnimation('apply-button_step-one')
        setTimeout(() => {
          setIsPromoSending(true);
          checkPromo();
        }, 500)
      } else {
        setIsSending(false)
        setStep(4)
        // setPromoMessage('Invalid promo code');
      }
    } else {
      toastr.error('', `Please, accept the terms`)
      setIsSending(false)

    }
  }
  const [focus, setFocus] = useState(false)

  const onfocusInput = () => {
    setFocus(true);
  };
  const onBlurInput = () => {
    setFocus(false);
  };

  ////////////////   NEW SignIn ////////////////////
  const [otpSent, setOtpSent] = useState(false)
  const [isSendingNew, setIsSendingNew] = useState(false)




  const mobileValidationNew = async (e) => {
    
    setIsSendingNew(true)

    if (mobile.length !== 10) {
      setMessage('This field is required!')
      setIsSendingNew(false)
      return
    } else if (isValid) {
      // actions.requestRecaptcha()

      setMessage('')
      const recaptchaResponseExecute =
        await recaptchaResponse.current.executeAsync()
      console.log('recaptchaResponse', recaptchaResponseExecute)
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberSignUp(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            // console.log('res', res)
            toastr.success('', `${res?.data?.result}`)
            setOtpSent(true)
          }
        })
        .catch((e) => {
          setIsSendingNew(false)
          toastr.error('', `${e}`)
        })

      console.log('SENDING MOB')
    }
  }

  const initialMinuteNew = 0,
  initialSecondsNew = 59
const [minutesNew, setMinutesNew] = useState(initialMinuteNew)
const [secondsNew, setSecondsNew] = useState(initialSecondsNew)

const setTimer = () => {
    let myIntervalNew = setInterval(() => {
      console.log('minusSet')
      if (secondsNew > 0) {
        setSecondsNew(secondsNew - 1)
        console.log('minus1')
      }
      if (secondsNew === 0) {
        if (minutesNew === 0) {
          clearInterval(myIntervalNew)
        } else {
          setMinutesNew(minutesNew - 1)
          setSecondsNew(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myIntervalNew)
    }
}

const showIntercomMessages = () => {
  let w = window
  let ic = w.Intercom
  if (typeof ic === 'function') {
    ic('messages')
  }
  // window.intercomSettings('showMessages');
}
  
useEffect(() => {
  if (otpSent){
  let myIntervalNew = setInterval(() => {
    console.log('minusSet')
    if (secondsNew > 0) {
      setSecondsNew(secondsNew - 1)
      console.log('minus1')
    }
    if (secondsNew === 0) {
      if (minutesNew === 0) {
        clearInterval(myIntervalNew)
      } else {
        setMinutesNew(minutesNew - 1)
        setSecondsNew(59)
      }
    }
  }, 1000)
  return () => {
    clearInterval(myIntervalNew)
  }
}
})

const nextNew = async (e) => {
  setIsSending(true)
  if (!otp) {
    setOtpMessage('This field is required!')
    setIsValid(false)
    setHasErrored(true)
    console.log('OTP Empty')
    setIsSending(false)
  } else if (otp.length != 4) {
    setIsValid(false)
    setHasErrored(true)
    setOtpMessage('OTP you entered is invalid')
    console.log('OTP invalid')
    setIsSending(false)
  } else if (!password || !confirmPassword) {
    setIsValid(false)
    setHasErrored(true)
    setPasswordMessage('This field is required!')
    setConfirmIsValid(false)
    setConfirmMessage('This field is required!')
    console.log('pass do not match')
    setIsSending(false)
  } else if (password !== confirmPassword) {
    setIsValid(false)
    setHasErrored(true)
    setPasswordMessage('Passwords do not match')
    setConfirmIsValid(false)
    setConfirmMessage('Passwords do not match')
    console.log('pass do not match')
    setIsSending(false)
  } else if (
    isOtpValid &&
    isValid &&
    isConfirmValid &&
    otp.length === 4 &&
    password === confirmPassword
  ) {
    setIsOtpValid(true)
    setMessage('')
    setHasErrored(false)
    setConfirmMessage('')
    setConfirmIsValid(true)
    setIsValid(true)
    console.log('VALIDATION PASSED')
    const data = {
      countryCode: `+${countryCode}`,
      mobileNumber: `${mobile}`,
      OTP: `${otp}`,
      password: `${password}`,
      confirmPassword: `${confirmPassword}`,
      atag: Cookies.get('atag'),
      clickId: Cookies.get('clickId'),
    }
    await actions
      .verifyOTP(data)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          setMemberCode(res?.data?.result?.memberCode);
          setToken(res.headers.authorization)
          setIsSignUpDone(true)
          setIsSending(false)
          try{
            // radar start
            try{
              if (window.location.search) {
                let s = "=";
                const queryString = window.location.search;
                const parameters = new URLSearchParams(queryString);
                parameters.forEach((value, key, urlParam) => {
                  if (value === "WATER") {
                    window.fbq('track', 'Lead');
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }

            const endSignUp = window.srtmCommands.push({event: "track.user.registration",payload: {action: "complete",userId: res?.data?.result?.memberCode}});
            console.log("!! endSignUp", endSignUp)
            // radar end
          } catch (e) {

          }

          login()
        }
      })
      .catch((e) => {
        setIsSending(false)
        toastr.error('', `${e}`)
      })

    // setPreVal(true)
  }
}
  const createGoogleWrapper = () => {
    window.google.accounts.id.initialize({
      client_id: "978590584847-gci18nvvja97am4076p1prkjgs1o8r0o.apps.googleusercontent.com",
      callback: async (response) => {
        const token = response.credential;

        const recaptchaResponse = await recaptchaResponse.current.executeAsync();
        await actions
          .requestAuthGoogle({
            recaptchaResponse,
            token: token,
          })
          .then((response) => {
            window.srtmCommands.push({
              event: "track.user.login",
              payload: {
                action: "complete",
                userId: response.data.result.memberCode
              }
            });
            setLoading(false);
          });
        console.log(response);
      },
      auto_select: false,
      ux_mode: "popup",
    })


    const btn = document.createElement('div');
    btn.style.display = 'none';
    btn.classList.add('custom-google-button');
    document.body.appendChild(btn);

    window.google.accounts.id.renderButton(btn, {});

    const googleLoginWrapper = btn.querySelector("div[role=button]");
    setGoogleButtonWrapper(btn.querySelector("div[role=button]"))
    return {
      click: () => {
        googleLoginWrapper.click();
      }
    }
  }

  const glogin = async () => {
    googleButtonWrapper.click();
    googleLoginWrapper.click();
  }

  return (
    <>
      <div className="signUpNew">
        <div className="signUpNew_header"></div>
      </div>

      <div className="registerWith-content-btns">
        <div className="register-button" onClick={() => glogin()}>
          <img className="icon" src={GoogleIcon} alt="" />
          <span className="register-button-link">Gmail</span>
        </div>
        <div className="register-button" onClick={() =>
          window.location.href = `https://funexch.authlink.me/?redirectUri=http://${window.location.host}`
        }>
          <img className="icon" src={WhatsappIcon} alt="" />
          <span className="register-button-link">Whatsapp</span>
        </div>
      </div>

      <div className='signUpNew-separator'>
                <div className='signUpNew-separator-rightLine'></div>
                <div className='signUpNew-separator-or'>OR</div>
                <div className='signUpNew-separator-leftLine'></div>
      </div>

      <div className="signUpNew_wrapper">
        <div
          className="signUpNew_closeButton"
          onClick={() => onDidDismiss(false)}
        ></div>

        <div className="loginPopUpContainer forgot-step">
          <div
            div
            className="signUpNew_inputContainer"
            style={{ marginBottom: '0' }}
          >
              <div className="signUpNew_inputContainer-input-wrapper">
                <div className="signUpNew__placeholder">+91</div>
              <input
                className="signUpNew_inputContainer-input phone"
                onFocus={onfocusInput}
                onBlur={onBlurInput}
                formcontrolname="username"
                placeholder="10 Digit Phone Number"
                name="username"
                type="tel"
                  autoComplete="username"
                onChange={(e) => validateMobile(e.target.value)}
              />
              </div>
            <div className="signUpNew_inputContainer_row">
              <div className="signUpNew_inputContainer_row-error">
                {message}
              </div>
              <span
                className={`signUpNew_inputContainer_row-counter ${
                  mobileNumCounter === 10 && 'counterDone'
                }`}
              >
                {mobileNumCounter}/10
              </span>
            </div>
            <div className="signUpNew_inputContainer_otp">
              <div
                className={`signUpNew_inputContainer_otp-button ${
                  otpSent ? 'otpButtonDisabled' : ''
                }`}
                onClick={() => (!otpSent ? mobileValidationNew() : null)}
              >
                Get OTP
              </div>
              </div>
            </div>

          <div div className="signUpNew_inputContainer">
              <input
              className="signUpNew_inputContainer-input"
                formcontrolname="otp"
                placeholder="OTP"
                name="otp"
                type="number"
                pattern="\d*"
              autocomplete="one-time-code"
                required
                maxLength={4}
                onChange={(e) => validateOtp(e.target.value)}
              />
            <div
              className={`signUpMessageError ${otpMessage ? 'otpMessage' : ''}`}
            >
              {otpMessage}
            </div>
            {isSendingNew && (
              <div className="signUpNew_inputContainer-resendOTP">
                  <div
                    style={
                      secondsNew === 0
                        ? { textDecoration: 'underline', cursor: 'pointer' }
                        : null
                    }
                    onClick={() => (secondsNew === 0 ? setSecondsNew(59) : null)}
                  >
                    <span
                      className="signUpNew_inputContainer-resendOTP"
                      onClick={secondsNew === 0 ? resendOTP : null}
                    >
                      Resend OTP
                    </span>
                    {minutesNew === 0 && secondsNew === 0 ? null : (
                      <span>
                        {' '}
                        in {minutesNew}:{secondsNew < 10 ? `0${secondsNew}` : secondsNew}
                      </span>
                    )}
                  </div>
            </div>
            )}
          </div>
          <div div className="signUpNew_inputContainer lock">
              <input
                required
                ref={ref_input}
                formcontrolname="122"
                placeholder="Password"
                name="122"
                className={
                hideFirstPass ? 'signUpNew_inputContainer-input signup-password' : 'signUpNew_inputContainer-input passwordDots'
                }
                autoComplete="off"
                onKeyDown={onKeyDown}
                onChange={(e) => validatePassword(e.target.value)}
              />
              <div
              className={`${hideFirstPass ? 'signUpNew_icons eyeOff' : 'signUpNew_icons eye'} `}
              onClick={() => setHideFirstPass(!hideFirstPass)}
            ></div>
              {/* <img
                className="icons"
                src={hideFirstPass ? eyeOff : eye}
                onClick={() => setHideFirstPass(!hideFirstPass)}
              /> */}
            <div className="signUpNew_inputContainer_row">
              <div className="signUpNew_inputContainer_row-error">
                {passwordMessage}
              </div>
              </div>
            </div>

          <div div className="signUpNew_inputContainer lock">
              <input
                formcontrolname="122"
                placeholder="Confirm Password"
                name="122"
                className={
                  hideSecondPass ? 'signUpNew_inputContainer-input signup-password' : 'signUpNew_inputContainer-input passwordDots'
                  }
                autoComplete="off"
                onKeyDown={onKeyDown}
                onChange={(e) => validatePasswordConfirm(e.target.value)}
              />
              <div
              className={`${hideSecondPass ? 'signUpNew_icons eyeOff' : 'signUpNew_icons eye'} `}
              onClick={() => setHideSecondPass(!hideSecondPass)}
            ></div>
              {/* <img
                className="icons"
                src={hideSecondPass ? eyeOff : eye}
                onClick={() => setHideSecondPass(!hideSecondPass)}
              /> */}
            <div className="signUpNew_inputContainer_row">
              <div className="signUpNew_inputContainer_row-error">
                {confirmMessage}
              </div>
            </div>
              </div>
          {/* <div div className="signUpNew_inputContainer">
              <input
                className={`signUpNew_inputContainer-input ${emailMessage ? 'invalid' : email && "valid"}`}
                formcontrolname="email"
                placeholder="Email"
                name="email"
                type="email"
                autoComplete="one-time-code"
                required
                onChange={(e) => validateEmail(e.target.value)}
                value={email}
              />
              <div className="signUpNew_inputContainer_row-error">{emailMessage}</div>
            </div>
            <div div className="signUpNew_inputContainer">
              <input
                className={`signUpNew_inputContainer-input`}
                formcontrolname="name"
                placeholder="Name"
                name="name"
                type="text"
                autoComplete="one-time-code"
                required
                onChange={(e) => validateName(e.target.value)}
                value={name}
              />
              <div className="signUpNew_inputContainer_row-error">{nameMessage}</div>
            </div> */}
            </div>

        <div className="pop-up-content__buttons  forgot-step two-buttons">
          {width < 1024 && (
              <div onClick={() => onDidDismiss(false)} className="pushable back-button">
                <div className="front refreshBtn">
                  <span>Back</span>
                </div>
              </div>
          )}
          <button
            onClick={nextNew}
            style={
              isSending && width < 1024
                ? { background: '#c0bdbd' }
                : { marginBottom: '10px' }
            }
                className="pushable next-button signUpButton"
                disabled={isDisabledButton()}>
              <span className="shadow"></span>
              <span className="edge "></span>
            {isSending && width > 1024 ? (
                <>
                  <span
                    style={isSending ? {background: '#c0bdbd'} : {}}
                    className="front refreshBtn login"></span>
                  <section className="signUpSpinner">
                  <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
            ) : width > 1024 ? (
              <span className="front refreshBtn login">Register</span>
            ) : null}
            {isSending && width < 1024 ? (
                <>
                  <span
                    className="front refreshBtn"></span>
                  <section className="signUpMobileSpinner">
                  <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
            ) : (
              width < 1024 && (
                  <div className="front refreshBtn">
                    <span>Register</span>
                    <div className="next-button-icon"></div>
                  </div>
              )
            )}
            </button>
        </div>

        <div className="signUpNew-or">Or</div>


        {CONFIG.isWhatsappOnSignUp === true &&
          <>
            <div className='signUpNew_whattsUp'>
              <div className='signUpNew_whattsUp_title'>Get your ready-made ID from whatsapp</div>
              <div className='signUpNew_whattsUp_button' onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
                {/* <a href={`https://wa.me/8888888888`}
                target="_blank"
                className="landing-footer-social-button whatsapp">
                <span>8888888888</span>
              </a> */}
                <div className='signUpNew_whattsUp_button-icon'></div>
                <div className='signUpNew_whattsUp_button-number'>click here to get ID on whatsapp</div>
              </div>
            </div></>}
          <div className="signUpNew_info" >
            <span className="signUpNew_info-text">Already have account? </span>&nbsp;
            <span onClick={() => actions.setTab(1)} className="signUpNew_info-link">Log In</span>
          </div>




          </div>
        </>
  )
}

export default connectLoginPopUp()(SignUpTab)
