import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestAuth } from '../../store/auth/auth.thunks'
import { requestBalance } from '../../store/balance/balance.thunks'
import { requestAuthGoogle } from '../../store/auth/auth.thunks'
import { requestRecaptcha } from '../../store/recaptcha/recaptcha.thunks'
import { getRecaptchaSuccess } from '../../store/recaptcha/recaptcha.slice'
import { setVersion } from '../../store/version/version.slice'
import { postNumberSignUp, resendOTP, verifyOTP, postCheckPromo, getPromotionsList} from '../../store/signUp/signUp.thunks'
import { postNumberForgotPassword, verifyForgotPasswordOTP} from '../../store/forgotPassword/forgotPassword.thunks'
import { setIsDetailedPromo, setIsTermCondition, setPromoInfo } from '../../store/signUp/signUp.slice'
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'


export const connectLoginPopUp = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      recaptcha: state.recaptcha,
      version: state.version,
      B2C: state.B2C,
      isDetailedPromo: state.signUp.isDetailedPromo,
      isTermCondition: state.signUp.isTermCondition,
      promoInfo: state.signUp.promoInfo,
      tab: state.loginNew.tab,
      promoListStore: state.signUp.promoListStore,
      signUp: state.signUp,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestAuth,
      requestAuthGoogle,
      requestRecaptcha,
      getRecaptchaSuccess,
      setVersion,
      requestBalance,
      postNumberSignUp,
      resendOTP,
      verifyOTP,
      postCheckPromo,
      getPromotionsList,
      setIsDetailedPromo,
      setPromoInfo,
      postNumberForgotPassword,
      verifyForgotPasswordOTP,
      setTab,
      setIsTermCondition,
      setLoginNew,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
